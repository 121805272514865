export const getRouteNameParsed = (name, searchKey = false) => {
  const routes = {
    ConsultaCobertura: "consulta-cobertura",
    EscolhaPlano: "escolha-plano",
    SemCobertura: "sem-cobertura",
    SemCoberturaLeadEnviado: "sem-cobertura-lead-enviado",
    Pagamento: "pagamento",
    Parabens: "sucesso",
    Endereco: "instalacao",
    DadosPessoais: "seus-dados",
    ComplementarDados: "complementar-dados",

    "consulta-cobertura": "consulta-cobertura",
    "escolha-plano": "escolha-plano",
    "dados-pessoais": "seus-dados",
    "sem-cobertura-lead-enviado": "sem-cobertura-lead-enviado",
    "sem-cobertura": "sem-cobertura",
    "complementar-dados": "complementar-dados",
    sucesso: "sucesso",
  };
  if (!searchKey) return routes[name] || "";

  const keys = Object.keys(routes);
  let keyIndex = -1;
  keys.forEach(key => {
    if (name.indexOf(key.toLowerCase()) >= 0) keyIndex = keys.indexOf(key);
  });
  if (keyIndex >= 0) {
    return routes[keys[keyIndex]];
  }
  return "";
};
