function initTracking() {
  // Verifica a disponibilidade do Objeto Tacking na página
  if (typeof Tracking !== "undefined") {
    // eslint-disable-next-line no-undef
    let tracking = new Tracking();
    // console.log("tracking: ", tracking);
    return tracking;
  }
}

/**
 * Procedure de instalação do plugin
 *
 * @param Vue
 */
const install = function(Vue) {
  Vue.prototype.$tracking = Vue.tracking = initTracking();
};

export default { install };
