import stringSanitize from "@utils/string-sanitize";
// import isMobileDevice from "@utils/is-mobile-device";
// import toKebabCase from "@utils/to-kebab-case";

// const inBrowser = typeof window !== "undefined";

export default class Events {
  pushDataLayer(obj) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(obj);
  }
  sendPageView(nodes = {}) {
    return this.pushDataLayer({
      event: "pageview",
      ...nodes,
    });
  }

  sendInteractionEvent(event, obj) {
    function _callUserInteraction(obj) {
      if (Object.keys(obj).length === 0 && obj.constructor === Object)
        throw new Error("obj is required");
      if (!obj.component) throw new Error("component is required");
      if (!obj.description) throw new Error("description is required");
      if (!obj.action) throw new Error("action is required");
      if (!obj.step) throw new Error("step is required");

      return {
        event_details: {
          component: obj.component ?? undefined,
          // description: stringSanitize(obj.description) ?? undefined,
          description: obj.description ?? undefined,
          action: obj.action ?? undefined,
          step: obj.step ?? undefined,
          value: 0,
        },
      };
    }
    function _callPopupInteraction(obj) {
      if (Object.keys(obj).length === 0 && obj.constructor === Object)
        throw new Error("obj is required");
      if (!obj.title) throw new Error("title is required");
      if (!obj.action) throw new Error("action is required");
      if (!obj.step) throw new Error("step is required");

      return {
        event_details: {
          popup_title: stringSanitize(obj.title) ?? undefined,
          action: obj.action ?? undefined,
          step: obj.step ?? undefined,
          value: 0,
        },
      };
    }

    if (!obj) throw new Error("obj is required");
    if (!event) throw new Error("event is required");
    let eventObj = { event };

    switch (event) {
      case "user_interaction": {
        eventObj = {
          ...eventObj,
          ..._callUserInteraction(obj),
        };
        break;
      }
      case "popup_interaction": {
        eventObj = {
          ...eventObj,
          ..._callPopupInteraction(obj),
        };
        break;
      }
      default: {
        throw new Error("Invalid event");
      }
    }
    this.pushDataLayer(eventObj);
  }

  sendViewEvent(event, obj) {
    const eventsList = ["user_view", "popup_view"];
    const userFields = ["component", "action", "description", "step", "value"];
    const popupFields = ["component", "action", "popup", "step", "value"];

    if (!obj) throw new Error("obj is required");
    if (!event) throw new Error("event is required");
    if (!eventsList.includes(event))
      throw new Error(`Invalid event. Allowed events: ${eventsList.join("|")}`);

    Object.keys(obj).forEach(key => {
      if (event === "user_view" && !userFields.includes(key))
        throw new Error(`Invalid field ${key}`);
      if (event === "popup_view" && !popupFields.includes(key))
        throw new Error(`Invalid field ${key}`);
    });

    this.pushDataLayer({
      event,
      event_details: obj,
    });
  }

  sendEcommerceEvent(event, obj) {
    const eventsList = [
      "view_item_list",
      "add_to_cart",
      "begin_checkout",
      "add_shipping_info",
      "add_payment_info",
      "purchase",
    ];
    if (!obj) throw new Error("obj is required");
    if (!event) throw new Error("event is required");
    if (!eventsList.includes(event))
      throw new Error(`Invalid event. Allowed events: ${eventsList.join("|")}`);
    this.pushDataLayer({
      event,
      ecommerce: obj,
    });
  }
}

// id == sku
// name == dados
// variant == nome
// category == 'internet'
