<script>
import IconBase from "@components/icons/_icon-base.vue";
import IconTim from "@components/icons/_icon-tim.vue";

export default {
  name: "ALoading",
  components: {
    IconBase,
    IconTim,
  },
  data: () => ({
    time: 0,
  }),
  mounted() {
    this.time = 0;
    setInterval(() => {
      this.time++;
    }, 1000);
  },
};
</script>

<template>
  <div
    class="fixed top-0 bottom-0 left-0 right-0 z-50 flex flex-col items-center justify-center bg-opacity-75 bg-dark-200 text-light"
  >
    <icon-base :size="16"><icon-tim /></icon-base>
    <transition>
      <p class="mt-12 text-xl font-normal text-light" v-if="time <= 1">
        Carregando
      </p>
      <p class="mt-12 text-xl font-normal text-light" v-if="time === 2">
        Carregando.
      </p>
      <p class="mt-12 text-xl font-normal text-light" v-if="time === 3">
        Carregando..
      </p>
      <p class="mt-12 text-xl font-normal text-light" v-if="time === 4">
        Carregando...
      </p>
      <p class="mt-12 text-xl font-normal text-light" v-if="time === 5">
        Carregando....
      </p>
      <p class="mt-12 text-xl font-normal text-light" v-if="time === 6">
        Só mais um instante
      </p>
      <p class="mt-12 text-xl font-normal text-light" v-if="time === 7">
        Só mais um instante.
      </p>
      <p class="mt-12 text-xl font-normal text-light" v-if="time === 8">
        Só mais um instante..
      </p>
      <p class="mt-12 text-xl font-normal text-light" v-if="time === 9">
        Só mais um instante...
      </p>
      <p class="mt-12 text-xl font-normal text-light" v-if="time > 9">
        Estamos quase lá...
      </p>
    </transition>
  </div>
</template>
