import axios from "axios";
// import { getStorage } from './localStorage';
import { DEFAULT_API_URLS } from "./enum";

import store from "@state/store";
import { SET_API_CALL_IN_PROGRESS } from "@state/rootStore";

function withBaseURLContext() {
  return process.env.NODE_ENV
    ? DEFAULT_API_URLS[process.env.NODE_ENV.toUpperCase()]
    : DEFAULT_API_URLS.development;
}

const HTTPClient = axios.create({ baseURL: withBaseURLContext() });

HTTPClient.interceptors.request.use(
  config => {
    store.commit(SET_API_CALL_IN_PROGRESS, true);
    config.timeout = 30000;
    // const token = getStorage('token');
    // if (token) {
    //   config.headers.common.Authorization = `Bearer ${token}`;
    // }
    return config;
  },
  response => Promise.reject(response)
);

HTTPClient.interceptors.response.use(
  response => {
    store.commit(SET_API_CALL_IN_PROGRESS, false);
    return response;
  },
  error => {
    store.commit(SET_API_CALL_IN_PROGRESS, false);
    return Promise.reject(error);
  }
);

export { HTTPClient };
