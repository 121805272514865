<script>
import IconBase from "@components/icons/_icon-base.vue";
import IconWhatsapp from "@components/icons/_icon-whatsapp.vue";
import IconFacebook from "@components/icons/_icon-facebook.vue";
import IconMail from "@components/icons/_icon-mail.vue";
import IconLink from "@components/icons/_icon-link.vue";
import AText from "@components/atoms/a-text.vue";
import ALink from "@components/atoms/a-link.vue";
import AButton from "@components/atoms/a-button.vue";
import ATooltip from "@components/atoms/a-tooltip.vue";
import stringSanitize from "@utils/string-sanitize";

import { mapGetters } from "vuex";
export default {
  name: "MSharedIcons",
  components: {
    IconBase,
    IconWhatsapp,
    IconFacebook,
    IconMail,
    IconLink,
    AText,
    ALink,
    AButton,
    ATooltip,
  },
  data: () => ({
    tooltipText: "Clique para copiar!",
    isClipboard: false,
  }),
  computed: {
    ...mapGetters({
      sharedLinks: "catalog/getSharedLinks",
    }),
  },
  methods: {
    sendInteractionEventBySidebar(iconName) {
      this.$gtm.sendInteractionEvent("user_interaction", {
        component: "icon",
        action: "click",
        step: "compartilhar-oferta",
        description: stringSanitize(iconName),
      });
    },
    async copyToClipboard(string) {
      this.isClipboard = true;
      await navigator.clipboard.writeText(string);
      this.tooltipText = "Copiado!";
      this.sendInteractionEventBySidebar("Copiar para área de transferência");
    },
    mouseLeave() {
      setTimeout(() => {
        this.isClipboard = false;
        this.tooltipText = "Clique para copiar!";
      }, 1000);
    },
  },
};
</script>

<template lang="pug">
  div
    AText(tag="p" class="text-base text-secondary-0" ignore-style) Compartilhe essa oferta
    .group_icons(class="flex mb-2 md:mb-0 md:mt-2")
      ATooltip(text="Compartilhe por Whatsapp!" position="top-right" class="ml-2 first:ml-0 mb-1 md:mb-0 md:mt-1 ")
        AButton(:href="sharedLinks.whatsapp" icon @click="sendInteractionEventBySidebar('Whatsapp')")
          IconBase
            IconWhatsapp
      ATooltip(text="Compartilhe pelo Facebook!" position="top-right" class="ml-2 first:ml-0 mb-1 md:mb-0 md:mt-1 ")
        AButton(:href="sharedLinks.facebook" icon @click="sendInteractionEventBySidebar('Facebook')")
          IconBase
            IconFacebook
      ATooltip(text="Compartilhe por E-mail!" position="top-center" class="ml-2 first:ml-0 mb-1 md:mb-0 md:mt-1 ")
        AButton(:href="sharedLinks.email" icon @click="sendInteractionEventBySidebar('E-mail')")
          IconBase
            IconMail
      ATooltip(:text="tooltipText" position="top-left" class="ml-2 first:ml-0 mb-1 md:mb-0 md:mt-1")
        AButton(href="javascript:void(0)" target="_self" icon @click="copyToClipboard(sharedLinks.link)" @mouseleave="mouseLeave")
          IconBase
            IconLink
</template>

<style lang="scss" scoped>
.group_icons {
  a {
    @apply flex;
    @apply items-center;
    @apply justify-center;
    @apply ml-1;
    @apply p-2;
    @apply text-center;
    @apply w-auto;
    svg {
      @apply inline;
      @apply m-0;
    }
  }
}
</style>
