<script>
export default {
  name: "APrice",
  props: {
    priceValue: {
      type: Number,
      required: true,
    },
  },
  computed: {
    formatedPrice() {
      const coin = "R$";
      const integer = Math.floor(this.priceValue);
      const decimal = Math.floor((this.priceValue % 1).toFixed(2) * 100);
      const price = coin + integer + "," + decimal + "/mês";
      return price;
    },
  },
};
</script>

<template lang="pug">
  div
    slot(:price="formatedPrice") {{formatedPrice}}
</template>
