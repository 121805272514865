import { store } from "@src/configs";
import Events from "./events";
// import stringSanitize from "@utils/string-sanitize";

/**
 * Procedure de instalação do plugin
 *
 * @param Vue
 * @param options
 */
const install = function(Vue, options = {}) {
  options = { ...options };

  if (options.vueRouter) RouterGuard(Vue, options);

  Vue.prototype.$gtm = Vue.gtm = new Events();
};

/**
 * Router guard para disparo automático de pageView
 *
 * @param Vue
 * @param vueRouter
 * @param {string[]} ignoredViews
 */
const RouterGuard = function(
  Vue,
  { vueRouter, ignoredViews, trackOnNextTick }
) {
  if (ignoredViews) ignoredViews = ignoredViews.map(view => view.toLowerCase());

  vueRouter.afterEach(to => {
    if (
      !to.name ||
      (ignoredViews && ignoredViews.indexOf(to.name.toLowerCase()) !== -1)
    ) {
      return;
    }

    const send = () => {
      const nodes = store.getters["order/getPageview"];
      Vue.gtm.sendPageView(nodes);
      // Vue.gtm.sendPageView({store});
    };
    if (trackOnNextTick) {
      Vue.nextTick(() => {
        send();
      });
    } else {
      send();
    }
  });

  return ignoredViews;
};

export default { install };
