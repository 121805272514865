<script>
export default {
  name: "ALink",
  props: {
    href: String,
    target: {
      type: String,
      validator: value => ["_self", "_blank"].includes(value),
    },
    to: {
      type: [Object, String],
    },
  },

  computed: {
    tag() {
      if (this.href) return "a";
      if (this.to) return "router-link";
      return "button";
    },
    computedTarget() {
      return this.target || (this.href ? "_blank" : null);
    },
  },
  methods: {
    handleClick(event) {
      /**
       * Click event
       * @type {Event}
       */
      this.$emit("click", event);
    },
  },
};
</script>

<template>
  <component
    :is="tag"
    :href="href"
    :target="computedTarget"
    :to="to"
    class="text-secondary"
    :class="{
      'leading-none font-normal text-sm underline': href,
      'leading-short font-medium no-underline flex items-center justify-center': to,
    }"
    @click="handleClick"
  >
    <slot />
  </component>
</template>
