import createUUID from "@utils/create-uuid";
import store from "@state/store";

function initOFDB() {
  let uuid = createUUID();
  store.dispatch("order/setIdSessionOFDB", uuid);

  // Verifica a disponibilidade do Objeto OFDB na página
  if (typeof OFDB !== "undefined") {
    // eslint-disable-next-line no-undef
    let ofdb = new OFDB(process.env.VUE_APP_SIGLA_OFDB, uuid, []);

    return ofdb;
  } else {
    // Propagar esta informação para o backend com o objetivo de passar este dado na API Transacional
    //"ofdb_load_error": true
  }
}

/**
 * Procedure de instalação do plugin
 *
 * @param Vue
 */
const install = function(Vue) {
  Vue.prototype.$ofdb = Vue.ofdb = initOFDB();
};

export default { install };
