var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.tag,{tag:"component",staticClass:"flex flex-no-wrap items-center content-center justify-center w-full transition-all rounded-full outline-none cursor-pointer a-button sm:w-auto focus:outline-none",class:{
    'bg-success hover:bg-success-200 active:bg-success-300 text-light': _vm.success,
    'bg-secondary hover:bg-secondary-200 active:bg-secondary-300 text-light': _vm.secondary,
    'bg-light hover:bg-light-200 active:bg-light-300 text-dark': _vm.neutral,
    'bg-primary hover:bg-primary-200 active:bg-primary-300 text-light': _vm.icon,
    'p-0 bg-primary hover:bg-primary-200 active:bg-primary-300 text-light w-full h-full sm:w-full': _vm.circle,
    'bg-light-100 hover:bg-light-100 active:bg-light-100 text-dark-0 cursor-not-allowed': _vm.disabled,
    'py-4 px-8 text-base': !_vm.compressed && !_vm.circle,
    'py-2 px-8 text-sm min-w-full': _vm.compressed && !_vm.circle,
  },attrs:{"id":_vm.id,"to":_vm.to,"href":_vm.href,"disabled":_vm.disabled,"target":_vm.computedTarget},on:{"click":_vm.handleClick,"mouseleave":function($event){return _vm.$emit('mouseleave')}}},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }