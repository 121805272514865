const Types = {
  NEXT_STEP: "NEXT_STEP",
  TOGGLE_MOREDETAILS: "TOGGLE_MOREDETAILS",
};

export const state = {
  breadcrumb: {
    steps: [1, 2, 3, 4],
    currentStep: 1,
  },
  moreDetails: {
    isShowing: false,
  },
};

export const getters = {
  steps: state => state.breadcrumb.steps,
  currentStep: state => state.breadcrumb.currentStep,
  isShowingMoreDetails: state => state.moreDetails.isShowing,
};

export const mutations = {
  [Types.NEXT_STEP]: (state, newStep) =>
    (state.breadcrumb.currentStep = newStep),
  [Types.TOGGLE_MOREDETAILS]: (state, payload) =>
    (state.moreDetails.isShowing = payload),
};

export const actions = {
  setNewStep: ({ commit }, newStep) => commit(Types.NEXT_STEP, newStep),
  toggleMoreDetails: ({ commit, getters, dispatch }, sku) => {
    if (sku) dispatch("catalog/setSelectedPlan", sku, { root: true });
    commit(Types.TOGGLE_MOREDETAILS, !getters.isShowingMoreDetails);
  },
};
