import Vue from "vue";
import Vuelidate from "vuelidate";

import { App, router, store } from "@src/configs";
// import configSentry from "./configs/config.sentry";
import configMock from "./configs/config.mock";
import configGtm from "./configs/config.gtm";

import tracking from "@src/plugins/tracking";
import ofdb from "@src/plugins/ofdb";

import "./tricks";
import "./design/index.scss";
// import "./registerServiceWorker";
import "./assets/tailwind.css";

Vue.config.productionTip = false;

Vue.use(Vuelidate);
Vue.use(tracking);
Vue.use(ofdb);

configMock();
configGtm(true);

const vueApp = new Vue({
  router,
  store,
  render: h => h(App),
}).$mount("#app");

// configSentry(vueApp);
export default vueApp;
