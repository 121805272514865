import { HTTPClient } from "@/utils/request.api";

export default {
  ConsultarViabilidade: ({ payload }) =>
    HTTPClient.post("ConsultarViabilidade", payload),
  SemViabilidade: ({ payload }) => HTTPClient.post("SemViabilidade", payload),
  DadosPessoais: ({ payload }) => HTTPClient.post("DadosPessoais", payload),
  Endereco: ({ payload }) => HTTPClient.post("Endereco", payload),
  Fatura: ({ payload }) => HTTPClient.post("Fatura", payload),
  DataInstalacao: ({ payload }) => HTTPClient.post("DataInstalacao", payload),
  ComplementarDados: ({ payload }) =>
    HTTPClient.post("ComplementarDados", payload),
  AtualizarProduto: ({ payload }) =>
    HTTPClient.post("AtualizarProduto", payload),
  AdicionarSVA: ({ payload }) => HTTPClient.post("AdicionarSVA", payload),
};
