let mock = () => {};

if (
  process.env.NODE_ENV === "development" &&
  process.env.VUE_APP_ENABLE_MOCK === true
) {
  mock = () =>
    import(
      /* webpackChunkName: "mock-server" */ "@src/server"
    ).then(({ makeServer }) => makeServer());
}

export default mock;
